import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import { Toast } from 'vant';
import Router from '../router'
import { setCache, getCache, clearCache } from '@/utils/cookies'
import { toFormData } from '@/utils/index'

// // 测试环境
// if (process.env.NODE_ENV == 'development') {
//     axios.defaults.baseURL = window.location.origin
// } else {
//     axios.defaults.baseURL = window.host || window.location.origin
// }

axios.defaults.baseURL = 'https://mix.cou3d.com'

const noToken = [
    '/api/sendCode'
]

axios.interceptors.request.use(
    config => {
        // console.log(config, 'config--')
        // 设置token
        const token = getCache('app-token');
        if (token && !noToken.includes(config.url)) {
            config.headers['authorization'] = token
        }
        if(config.url === '/upload') {
            config.headers['Content-Type'] = 'multipart/form-data'
        }
        if (config.url !== '/upload' && config.method.toLocaleLowerCase() === 'post') {
            config.data = toFormData(config.data || {})
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    response => {
        
        if (response.status == '200') {
            if (response.data.code == '200') {
                return response.data
            }
            Toast(response.data.message)
            if (response.data.code == 403) {
                clearCache('app-token')
                Router.push('/login')
            }
        }
        if(response.status == 403) {
            clearCache('app-token')
            Router.push('/login')
            Toast('请登录')
        }
        return response.data
    },
    error => {
        const errorData = error.response && error.response.data || {}
        const message = errorData.message
        if(error.response.status == 403) {
            clearCache('app-token')
            Router.push('/login')
            Toast('请登录')
        }
        return Promise.reject(error)
    }
)


Vue.prototype.$http = axios
export default axios